import React, { Component, useState } from "react";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import { Form, Accordion } from "react-bootstrap";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import "rc-slider/assets/index.css";
import "../Filter/Filter.css";
import "rc-tooltip/assets/bootstrap.css";
import { connect } from "react-redux";
import Loading from "../Commons/Loading";
import {
  filterTeamList,
  resetFilter,
} from "../../redux-container/accounts/action";
import { filterTeam, getTeam, getTeamCaller, resetTeamFilter } from "../../redux-container/team/action";
import {
  filterUserList,
  resetUserFilter,
} from "../../redux-container/settings/manage_users/action";
//import { Select, MenuItem } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import toaster from "../Toast/toaster";
import ConvFilter from "../Conversations_Dashboard/ConversationFilter/ConvFilter";
import DashboardFilters from "./DashboardFilters";
import coeService from "../Services/coe.service";
import dashboardService from "../Services/dashboard.service";
import AgentFilter from "./AgentFilter";
import { USER_DETAIL } from "../../Constants/constant"
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import userService from "../Services/user.service";
import ReportFilter from "./ReportFilter";
import { setCOE } from "../../redux-container/commons/action";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.clintType = "BOTH";
    this.type = "Push";
    this.setOpen = false;
    this.Lists = {
      Roles: ["Software Engineer", "Developer", "Manager"],
      COE: ["Ceo1", "Ceo2", "Ceo3"],
      Bucket: ["Bucket1", "Bucket2", "Bucket3"],
      Datelist: [
        "Yesterday",
        "Last 7 Days",
        "Last 15 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
        "Custom Date",
      ],
    };
    this.firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.queryString = window.location.search;
    this.urlParams = new URLSearchParams(this.queryString);

    this.localFrom = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    this.localTo = JSON.parse(localStorage.getItem("AGENT"))?.toD


    this.storedData = JSON.parse(localStorage.getItem("AGENT"))
    this.dateFormat = JSON.parse(localStorage.getItem("TIME_FORMAT")).value
    const {redirectedData} = this.props;


    this.state = {
      callScore: [0, 100],
      pitchScore: [0, 100],
      ahtScore: [0, 120],
      data: {
        selectedDate: this.storedData?.selectedDate ? this.storedData?.selectedDate : "",
        selectedRange: this.storedData?.selectedRange ? this.storedData?.selectedRange : "",
        employee: "",
        employeeId: redirectedData?.employeeId !== null ? redirectedData?.employeeId : this.storedData?.empId ? this.storedData?.empId : "",
        accountEmployeeId: "",
        userEmployeeId: "",
        manager: "",
        managerId: "",
        coe: "",
        teamUser: redirectedData?.employeeName !== null ? {value : redirectedData?.employeeName} : "",
        user: "",
        userCoeId: "",
        teamCOE: this.storedData && this.storedData.teamCOE ? this.storedData.teamCOE.length == 1 ? this.storedData?.teamCOE[0] : [this.storedData?.selectedCOE] : "",
        coeId: this.storedData && this.storedData.selectedCoeRange ? this.storedData.selectedCoeRange : "",
        role: this.storedData && this.storedData.role ? this.storedData?.role : "",
        designation: this.storedData && this.storedData.designation ? this.storedData?.designation : "",
        caller: this.storedData && this.storedData.type ? this.storedData.type : "",
        call: redirectedData?.agentCallScore !== undefined ? redirectedData?.agentCallScore : this.storedData && this.storedData.callScore ? this.storedData?.callScore : [0, 100],
        pitch: this.storedData && this.storedData.pitchScore ? this.storedData?.pitchScore : [0, 100],
        users: [],
        aht: redirectedData?.aht !== undefined ? redirectedData.aht : this.storedData && this.storedData.aht ? this.storedData?.aht : [0, 300],
        callsFrom: this.storedData && this.storedData.callsFrom ? this.storedData?.callsFrom : "",
        callsTo: this.storedData && this.storedData.callsTo ? this.storedData?.callsTo : "",
        team_fromD:
          this.localFrom ? this.localFrom :
            `${this.firstDay.getDate()}-${this.firstDay.getMonth() + 1}-${this.firstDay.getFullYear()}`,
        team_toD:
          this.localTo ? this.localTo :
            `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`,
      },
      userId: this.props.userId,
      columns: this.props.columns,
      coeOptions: '',
      loader: false,
      errorMessage: "",
      value: "",
      suggestions: [],
      dashboard_fromD: "",
      dashboard_toD: "",
      momentBucketId: "",
      opendp: false,
      opendp1: false,
      fromD: this.storedData?.fromD ? new Date(`${this.storedData?.fromD.split("-")[1]}-${this.storedData?.fromD.split("-")[0]}-${this.storedData?.fromD.split("-")[2]}`) : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toD: this.storedData?.toD ? new Date(`${this.storedData?.toD.split("-")[1]}-${this.storedData?.toD.split("-")[0]}-${this.storedData?.toD.split("-")[2]}`) : new Date(),
      coeExternalId: [],
      filteredList: {},
      Cname: [],
      mobile: "",
      file: "",
      Sname: [],
      selectedS: "",
      agentExId: "",
      Sros: [],
      Cscore: [],
      health: "",
      CallDur: [],
      words: [],
      from: "",
      to: "",
      moment: [],
      momentId: [],
      coe: [],
      pitch: [],
      prob: [],
      opp: [],
      urgency: [],
      resetField: false,
      coeId: [],
      resetButton: false,
      defCoe: "",
      defFrom: "",
      defTo: "",
      selectedCOE: "",
      selectedCoeRange: "",
      selectedDate: "",
      selectedRange: "",
      momentBucket: "",
      selectedCustomer: "",
      selectedMobile: "",
      defaultAgentCoe: "",
      frequency: "",
      reportType: "",
      disableApply: false
    };

    console.log(this.state,"ckdckkcbekc")
    this.startDate = new Date();
    this.myRef = React.createRef();
    this.formRef = React.createRef();
    this.toggleClick = this.toggleClick.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.displayFilter = this.displayFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.displayDatePicker = this.displayDatePicker.bind(this);
    //  this.callLog = this.callLog.bind(this);
    this.setInputs = this.setInputs.bind(this);
    //  this.pitchLog = this.pitchLog.bind(this);
    this.handleTeamFromDate = this.handleTeamFromDate.bind(this)
    this.handleTeamToDate = this.handleTeamToDate.bind(this)

    console.log(props,"agent")

    this.inputProps = {
      placeholder: "Enter User Name",
      value: "",
      name: "employeeName",
      onChange: this.handleChange,
    };



    this.marks = [
      {
        value: 0,
        label: "0%",
      },
      {
        value: 100,
        label: "100%",
      },
    ];
    this.score = [
      {
        value: 0,
        label: "0 min.",
      },
      {
        value: 100,
        label: "120 min.",
      },
    ];
    this.ahTScore = [
      {
        value: 0,
        scaledValue: 0,
        label: "0 min.",
      },
      {
        value: 300,
        scaledValue: 300,
        label: "300 min.",
      },
    ];
  }


  componentDidMount() {
    let userRole = JSON.parse(localStorage.getItem(USER_DETAIL))?.userRole

    if (JSON.parse(localStorage.getItem("AGENT")) && (this.props.componentType == 'FILTER TEAM_TAB')) {
      this.props.filterTeam(this.state.data,this.state.userId,this.state.columns)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.userId !== prevState.userId) {
      return { userId: nextProps.userId };
    }
    return null;
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.state.userId !== prevState.userId) {
      this.localFrom = JSON.parse(localStorage.getItem("AGENT"))?.fromD
      this.localTo = JSON.parse(localStorage.getItem("AGENT"))?.toD
      this.storedData = JSON.parse(localStorage.getItem("AGENT"))
        this.setState({
            data: {
                selectedDate: this.storedData?.selectedDate ? this.storedData?.selectedDate : "",
                selectedRange: this.storedData?.selectedRange ? this.storedData?.selectedRange : "",
                employee: "",
                employeeId: this.storedData?.empId ? this.storedData?.empId : "",
                accountEmployeeId: "",
                userEmployeeId: "",
                manager: "",
                managerId: "",
                coe: "",
                teamUser: "",
                user: "",
                userCoeId: "",
                teamCOE: this.storedData?.teamCOE && this.storedData.teamCOE.length === 1 ? this.storedData.teamCOE[0] : "",
                coeId: this.storedData.selectedCoeRange ? this.storedData.selectedCoeRange:"",
                role: this.storedData?.role ? this.storedData.role : "",
                designation: this.storedData?.designation ? this.storedData.designation : "",
                caller: this.storedData?.type ? this.storedData.type : "",
                call: this.storedData?.callScore ? this.storedData.callScore : [0, 100],
                pitch: this.storedData?.pitchScore ? this.storedData.pitchScore : [0, 100],
                users: [],
                aht: this.storedData?.aht ? this.storedData.aht : [0, 300],
                callsFrom: this.storedData?.callsFrom ? this.storedData.callsFrom : "",
                callsTo: this.storedData?.callsTo ? this.storedData.callsTo : "",
                team_fromD: this.localFrom ? this.localFrom : `${this.firstDay.getDate()}-${this.firstDay.getMonth() + 1}-${this.firstDay.getFullYear()}`,
                team_toD: this.localTo ? this.localTo : `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`,
            }
        }, () => {
            if (JSON.parse(localStorage.getItem("AGENT")) && (this.props.componentType === "FILTER TEAM_TAB" || this.props.componentType === "FILTER TEAM")) {
                console.log(this.state,"scxjbvsjcs")
                this.props.filterTeam(this.state.data, this.state.userId);
            }
        });
    }
}

  disableDashBoardApply = (val) => {
    this.setState({disableApply: val})
  }
  resetForm = () => {
    console.log(this.state,'lolo*');
    this.setState({ resetField: true })
  }
  resetFormState = () => {
    console.log(this.state,'lolo&');
    this.setState({ resetField: false })
  }
  handleSubmit(e) {
    this.sidebarObj.toggle();
    this.setState({ loader: true });

    e.preventDefault();

    if (Object.keys(this.state).length !== 0) {
      if (this.props.componentType === "FILTER TEAM") {
        this.props.filterTeamList(this.state.data);
      }
      if (this.props.componentType === "FILTER USERS") {
        this.props.filterUserList(this.state.data);
      }
      if (this.props.componentType === "FILTER TEAM_TAB") {
        if (
          parseInt(this.state.data.callsFrom) >
          parseInt(this.state.data.callsTo)
        ) {
          this.state.errorMessage = "Call range is invalid";
          this.sidebarObj.toggle(false);
        }
        else {
          this.props.filterTeam(this.state.data,this.state.userId,this.state.columns);
          this.coeName = this.props.coes.map(item => { return item.coeName })
          let storedData = JSON.parse(localStorage.getItem("AGENT"))
          localStorage.setItem("AGENT", JSON.stringify({
            "pageSelected": storedData?.pageSelected,
            "analysis": storedData?.analysis,
            "productivity": storedData?.productivity,
            "fromD": this.state.data.team_fromD ? this.state.data.team_fromD : storedData?.fromD,
            "toD": this.state.data.team_toD ? this.state.data.team_toD : storedData.toD,
            "agentDetailCOE": storedData?.agentDetailCOE,
            "userIds": storedData?.userIds,
            "userNames": storedData?.userNames,
            "empId": this.state.data.employeeId ? this.state.data.employeeId : storedData?.empId,
            "empName": this.state.data.teamUser,
            "role": this.state.data.role,
            "designation": this.state.data.designation,
            "type": this.state.data.caller,
            "teamCOE": this.state.data.teamCOE ? this.state.data.teamCOE : this.storedData?.teamCOE,
            "callsFrom": this.state.data.callsFrom,
            "callsTo": this.state.data.callsTo,
            "aht": this.state.data.aht,
            "callScore": this.state.data.call,
            "pitchScore": this.state.data.pitch,
            "customerName": storedData?.customerName,
            "customerMobile": storedData?.customerMobile,
            "fileName": storedData?.fileName,
            "sellerROS": storedData?.sellerROS,
            "sellerName": storedData?.sellerName,
            "selectedSeller": storedData?.selectedSeller,
            "cScore": storedData?.cScore,
            "callHealth": storedData?.callHealth,
            "callDuration": storedData?.callDuration,
            "words": storedData?.words,
            "moment": storedData?.moment,
            "momentId": storedData?.momentId,
            "pitch": storedData?.pitch,
            "prob": storedData?.prob,
            "opportunity": storedData?.opp,
            "urgency": storedData?.urgency,
            "coe": this.state.coeExternalId.length > 0 ? this.state.coeExternalId : this.storedData?.coe,
            "selectedCOE": this.state.selectedCOE ? this.state.selectedCOE : this.storedData?.selectedCOE,
            "selectedCoeRange": this.state.selectedCoeRange ? this.state.selectedCoeRange : this.storedData?.selectedCoeRange,
            "selectedDate": this.state.data.selectedDate ? this.state.data.selectedDate : this.storedData?.selectedDate,
            "selectedRange": this.state.data.selectedRange ? this.state.data.selectedRange : this.storedData?.selectedRange,
            "momentBucket": this.storedData?.momentBucket,
            "momentBucketId": this.storedData?.momentBucketId,
            "frequency": this.storedData?.frequency,
            "reportType": this.storedData?.reportType
          }))
          this.state.errorMessage = "";
        }
      }
      if (this.props.componentType == 'Dashboard-Sales' || this.props.componentType == 'Dashboard-Support') {
        this.props.filterData({ from: this.state.dashboard_fromD, to: this.state.dashboard_toD, externalIds: this.state?.coeExternalId?.length > 1 || typeof (this.state.coeExternalId) == 'object' ? this.state.coeExternalId : [this.state.coeExternalId], loader: true, selectedCOE: this.state.selectedCOE, selectedDate: this.state.selectedDate, momentBucket: this.state.momentBucket, momentBucketId: this.state.momentBucketId })
        let from = JSON.parse(localStorage.getItem("AGENT"))
        
        if(this.state.selectedCoeRange){
          this.props?.setCoe(this.state.selectedCoeRange)
        }
        if(this.state.momentBucketId){
        localStorage.setItem("MOMENT_ID",this.state.momentBucketId)
        localStorage.setItem("MOMENT_BUCKETS",this.state.momentBucket)
        }
        localStorage.setItem("selectedPage",1)
        localStorage.setItem("AGENT", JSON.stringify({
          "pageSelected": from?.pageSelected,
          "analysis": from?.analysis,
          "productivity": from?.productivity,
          "fromD": this.state.dashboard_fromD ? this.state.dashboard_fromD : from.fromD,
          "toD": this.state.dashboard_toD ? this.state.dashboard_toD : from.toD,
          "coe": this.state.coeExternalId && (this.state?.coeExternalId?.length > 1 || typeof (this.state.coeExternalId) == 'object') ? this.state.coeExternalId : this.state.coeExternalId && (!this.state?.coeExternalId?.length > 1 || typeof (this.state.coeExternalId) != 'object') ? [this.state.coeExternalId] : from?.coe,
          "agentDetailCOE": from?.agentDetailCOE,
          "selectedCOE": this.state.selectedCOE ? this.state.selectedCOE : from.selectedCOE,
          "selectedCoeRange": this.state.selectedCoeRange ? this.state.selectedCoeRange : from.selectedCoeRange,
          "userIds": from?.userIds,
          "userNames": from?.userNames,
          "empId": from?.employeeId,
          "empName": from?.user,
          "role": from?.role,
          "designation": from?.designation,
          "type": from?.type,
          "teamCOE": this.state.selectedCOE ? this.state?.selectedCOE : from?.teamCOE,
          "callsFrom": from?.callsFrom,
          "callsTo": from?.callsTo,
          "aht": from?.aht,
          "callScore": from?.callScore,
          "pitchScore": from?.pitchScore,
          "customerName": from?.customerName,
          "customerMobile": from?.customerMobile,
          "fileName": from?.fileName,
          "sellerROS": from?.sellerROS,
          "sellerName": from?.sellerName,
          "selectedSeller": from?.selectedSeller,
          "cScore": from?.cScore,
          "callHealth": from?.callHealth,
          "callDuration": from?.callDuration,
          "words": from?.words,
          "moment": from?.moment,
          "momentId": from?.momentId,
          // "coeName":this.state.coe,
          "pitch": from?.pitch,
          "prob": from?.prob,
          "opportunity": from?.opp,
          "urgency": from?.urgency,
          "selectedDate": this.state.selectedDate ? this.state.selectedDate : from.selectedDate,
          "selectedRange": this.state.selectedRange ? this.state.selectedRange : from.selectedRange,
          "momentBucket": this.state.momentBucket ? this.state.momentBucket : from.momentBucket,
          "momentBucketId": this.state.momentBucketId ? this.state.momentBucketId : from.momentBucketId,
          "frequency": from?.frequency,
          "reportType": from?.reportType
        }))
      }
      if (this.props.componentType == 'Conversation-Sales' || this.props.componentType == 'Conversation-Support') {
        let from = JSON.parse(localStorage.getItem("AGENT"))
        if(this.state.selectedCoeRange){
          this.props?.setCoe(this.state.selectedCoeRange)
        }
        this.props.filterCallList({
          customerName: this.state.Cname,
          customerMobile: this.state.mobile,
          fileName: this.state.file,
          sellerROS: this.state.Sros,
          sellerName: this.state.Sname,
          selectedSeller: this.state.selectedS,
          agentExId: this.state.agentExId,
          callScore: this.state.Cscore,
          callHealth: this.state.health,
          fromD: this.state.from,
          toD: this.state.to,
          callDuration: this.state.CallDur,
          words: this.state.words,
          moment: this.state.momentId,
          coeName: this.state.coe,
          coeExId: this.state.coeExternalId,
          pitch: this.state.pitch,
          prob: this.state.prob,
          opportunity: this.state.opp,
          urgency: this.state.urgency,
          loader: true
        })
        console.log(this.state.health)
        localStorage.setItem("selectedPage",1)
        localStorage.setItem("AGENT", JSON.stringify({
          "pageSelected": '',
          "analysis": from?.analysis,
          "productivity": from?.productivity,
          "fromD": this.state.from ? this.state.from : from?.fromD,
          "toD": this.state.to ? this.state.to : from?.toD,
          "agentDetailCOE": from?.agentDetailCOE,
          "coe": this.state.coeExternalId ? this.state.coeExternalId : from?.coe,
          "selectedCOE": this.state.coe ? this.state.coe : from?.selectedCOE,
          "selectedCoeRange": this.state.selectedCoeRange ? this.state.selectedCoeRange : from?.selectedCoeRange,
          "userIds": from?.userIds,
          "userNames": from?.userNames,
          "empId": from?.employeeId,
          "empName": from?.user,
          "role": from?.role,
          "designation": from?.designation,
          "type": from?.type,
          "teamCOE": this.state.coe ? this.state.coe : from?.teamCOE,
          "callsFrom": from?.callsFrom,
          "callsTo": from?.callsTo,
          "aht": from?.aht,
          "callScore": from?.callScore,
          "pitchScore": from?.pitchScore,
          "customerName": this.state.Cname != '' ? this.state.Cname : this.state?.Cname == '' ? '' : from?.customerName,
          "customerMobile": this.state.mobile && this.state.mobile != '' ? this.state.mobile : this.state.mobile == '' ? '' : from?.customerMobile,
          "fileName": this.state.file,
          "sellerROS": this.state.Sros ? this.state.Sros : from?.sellerROS,
          "sellerName": this.state.Sname ? this.state.Sname : from?.sellerName,
          "selectedSeller": this.state.selectedS != '' ? this.state.selectedS : this.state.selectedS == '' ? "" : from?.selectedSeller,
          "cScore": this.state.Cscore ? this.state.Cscore : from?.cScore,
          "callHealth": this.state.health,
          "callDuration": this.state.CallDur ? this.state.CallDur : from?.callDuration,
          "words": this.state.words ? this.state.words : undefined,
          "moment": this.state.moment ? this.state.moment : from?.moment,
          "momentId": this.state.momentId ? this.state.momentId : from?.momentId,
          // "coeName":this.state.coe,
          "pitch": this.state.pitch ? this.state.pitch : from?.pitch,
          "prob": this.state.prob ? this.state.prob : from?.prob,
          "opportunity": this.state.opp ? this.state.opp : from?.opp,
          "urgency": this.state.urgency ? this.state.urgency : from?.urgency,
          "selectedDate": this.state.selectedDate ? this.state.selectedDate : from?.selectedDate,
          "selectedRange": this.state.selectedRange ? this.state.selectedRange : from?.selectedRange,
          "momentBucket": from?.momentBucket,
          "momentBucketId": this.storedData?.momentBucketId,
          "frequency": from?.frequency,
          "reportType": from?.reportType
        }))
        console.log(this.state,"filterState========>506")
        this.props?.historyObject!=null&&this.props?.setEmptyObject()
        if(this.state?.Sname?.length > 0 && this.state?.Sname !== undefined){
          this.props?.handleCallViewChange()
        }else{
          this.props?.handleCallViewChange(true)
        }
      }
      if (this.props.componentType == 'AGENT-FILTER') {
        this.props.filterData({ coeId: this.state.coeId, from: this.state.fromD, to: this.state.toD })
        let from = JSON.parse(localStorage.getItem("AGENT"))
        from = {
          "pageSelected": from?.pageSelected,
          "analysis": from?.analysis,
          "productivity": from?.productivity,
          "fromD": this.state.fromD ? this.state.fromD : from.fromD,
          "toD": this.state.toD ? this.state.toD : from.toD,
          "agentDetailCOE": this.state.coeId ? this.state.coeId : this.state.defaultAgentCoe,
          "coe": from?.coe,
          "empName": from.empName ? from.empName : this.state.data.user,
          "selectedCOE": this.storedData?.selectedCOE,
          "selectedCoeRange": this.storedData?.selectedCoeRange,
          "userIds": from?.userIds,
          "userNames": from?.userNames,
          "empId": from?.employeeId,
          "empName": from?.user,
          "role": from?.role,
          "designation": from?.designation,
          "type": from?.type,
          "teamCOE": from?.teamCOE,
          "callsFrom": from?.callsFrom,
          "callsTo": from?.callsTo,
          "aht": from?.aht,
          "callScore": from?.callScore,
          "pitchScore": from?.pitchScore,
          "customerName": from?.customerName,
          "customerMobile": from?.customerMobile,
          "fileName": from?.fileName,
          "sellerROS": from?.sellerROS,
          "sellerName": from?.sellerName,
          "selectedSeller": from?.selectedSeller,
          "cScore": from?.cScore,
          "callHealth": from?.callHealth,
          "callDuration": from?.callDuration,
          "words": from?.words,
          "moment": from?.moment,
          "momentId": from?.momentId,
          "pitch": from?.pitch,
          "prob": from?.prob,
          "opportunity": from?.opp,
          "urgency": from?.urgency,
          "selectedDate":this.state.selectedDate?this.state.selectedDate:from?.selectedDate,
          "selectedRange": this.state.selectedRange?this.state?.selectedRange:from?.selectedRange,
          "momentBucket": from?.momentBucket,
          "momentBucketId": from?.momentBucketId,
          "frequency": from?.frequency,
          "reportType": from?.reportType
        }
        localStorage.setItem("AGENT", JSON.stringify(from))
      }
      if (this.props.componentType == 'REPORTS') {
        this.props.filterData({ externalId: this.state.coeExternalId, selectedCOE: this.state.selectedCOE, selectedCOERange: this.state.selectedCoeRange, frequency: this.state.frequency, reportType: this.state.reportType })
        let from = localStorage.getItem("AGENT")
        localStorage.setItem("AGENT", JSON.stringify({
          "pageSelected": from?.pageSelected,
          "analysis": from?.analysis,
          "productivity": from?.productivity,
          "fromD": this.state?.fromD ? this.state?.fromD : from?.fromD,
          "toD": this.state?.toD ? this.state?.toD : from?.toD,
          "coe": this.state.coeExternalId && (this.state?.coeExternalId?.length > 1 || typeof (this.state.coeExternalId) == 'object') ? this.state.coeExternalId : this.state.coeExternalId && (!this.state?.coeExternalId?.length > 1 || typeof (this.state.coeExternalId) != 'object') ? [this.state.coeExternalId] : from?.coe,
          "agentDetailCOE": from?.agentDetailCOE,
          "selectedCOE": this.state.selectedCOE ? this.state.selectedCOE : from.selectedCOE,
          "selectedCoeRange": this.state.selectedCoeRange && this.state.selectedCoeRange.length > 0 ? this.state.selectedCoeRange : from.selectedCoeRange,
          "userIds": from?.userIds,
          "userNames": from?.userNames,
          "empId": from?.employeeId,
          "empName": from?.user,
          "role": from?.role,
          "designation": from?.designation,
          "type": from?.type,
          "teamCOE": from?.teamCOE,
          "callsFrom": from?.callsFrom,
          "callsTo": from?.callsTo,
          "aht": from?.aht,
          "callScore": from?.callScore,
          "pitchScore": from?.pitchScore,
          "customerName": from?.customerName,
          "customerMobile": from?.customerMobile,
          "fileName": from?.fileName,
          "sellerROS": from?.sellerROS,
          "sellerName": from?.sellerName,
          "selectedSeller": from?.selectedSeller,
          "cScore": from?.cScore,
          "callHealth": from?.callHealth,
          "callDuration": from?.callDuration,
          "words": from?.words,
          "moment": from?.moment,
          "momentId": from?.momentId,
          // "coeName":this.state.coe,
          "pitch": from?.pitch,
          "prob": from?.prob,
          "opportunity": from?.opp,
          "urgency": from?.urgency,
          "selectedDate": this.state?.selectedDate ? this.state?.selectedDate : from.selectedDate,
          "selectedRange": this.state?.selectedRange ? this.state?.selectedRange : from.selectedRange,
          "momentBucket": from.momentBucket,
          "momentBucketId": from.momentBucketId,
          "frequency": this.state.frequency ? this.state.frequency : from?.frequency,
          "reportType": this.state.reportType ? this.state.reportType : from?.reportType
        }))
      }
    } else {
      toaster.error("select property to filter table");
    }
    setTimeout(() => {
      this.setState({ loader: false });
    }, 2000);
  }

  onResetClick(e) {
    console.log(this.props,'lolo');
    console.log(this.coeId,this.state.coeId,"skcbvskjbc")
    this.loader = false;
    this.sidebarObj.toggle();
    this.coeId = this.props?.coes?.map(item => { return item.coeId })
    console.log(this.coeId,'lolo')
    console.log(this.coeName, 'lolo')
    this.state.errorMessage = "";
    this.state.data.user = "";
    this.state.data.coe = "";
    this.setState({
      data: {
        userEmployeeId: "",
        accountEmployeeId: "",
        employeeId: "",
        manager: "",
        user: "",
        coe: "",
        teamCOE: "Select All",
        coeId: this.coeId ? this.coeId : JSON.parse(localStorage.getItem("AGENT")).selectedCOERange,
        role: "",
        designation: "",
        caller: "",
        call: [0, 100],
        pitch: [0, 100],
        aht: [0, 300],
        callsFrom: "",
        callsTo: "",
        team_fromD: `${this.firstDay.getDate()}-${this.firstDay.getMonth() + 1}-${this.firstDay.getFullYear()}`,
        team_toD: `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`,
      },
      fromD: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toD: new Date(),
      userId: JSON.parse(localStorage.getItem('USER_DETAIL'))?.userId
      // resetField:true
    });
    this.setState({userId : JSON.parse(localStorage.getItem("USER_DETAIL")).userId})
    console.log(this.state,"sacxskcaxknx")

    let fromD = `${(this.firstDay.getDate()) < 10 ? '0' + (this.firstDay.getDate()) : (this.firstDay.getDate())}-${(this.firstDay.getMonth() + 1) < 10 ? '0' + (this.firstDay.getMonth() + 1) : (this.firstDay.getMonth() + 1)}-${this.firstDay.getFullYear()}`
    let toD = `${(new Date().getDate()) < 10 ? '0' + (new Date().getDate()) : (new Date().getDate())}-${(new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)}-${new Date().getFullYear()}`

    this.setState({ callScore: [0, 100] });
    this.setState({ pitchScore: [0, 100] });
    this.setState({ ahtScore: [0, 120] });

    if (this.props.componentType === "FILTER TEAM") {
      console.log('hellobolo!');
      this.props.resetFilter();
    } else if (this.props.componentType === "FILTER USERS") {
      console.log('hellobolo@');
      this.props.resetUserFilter();
    } else if (this.props.componentType === "FILTER TEAM_TAB") {
      console.log('hellobolo#');
      this.coeName = this.props.coes.map(item => { return item.coeName })
      this.coeId = this.props.coes.map(item => { return item.coeId })
      console.log(this.coeName,this.coeId,"jcbvckj")
      this.props.resetTeamFilter(this.coeId);
      // this.props.resetUserId()
      localStorage.setItem("AGENT", JSON.stringify({ "fromD": `${this.firstDay.getDate()}-${this.firstDay.getMonth() + 1}-${this.firstDay.getFullYear()}`, "toD": `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`, "selectedDate": "This Month", "selectedRange": 5, "selectedCOE": this.coeName, "selectedCoeRange": this.coeId, "coe" : this?.storedData?.coe }))
    }
    if (this.props.componentType == 'Dashboard-Sales' || this.props.componentType == 'Dashboard-Support') {
      console.log('hellobolo$');
      this.resetForm()
      this.props.filterData({})
      localStorage.setItem("AGENT", JSON.stringify({ "fromD": fromD, "toD": toD, "momentBucket": localStorage.getItem("MOMENT_BUCKETS"), "momentBucketId": localStorage.getItem("MOMENT_ID") , "coe" : this?.storedData?.coe }))
    }
    if (this.props.componentType == 'Conversation-Sales' || this.props.componentType == 'Conversation-Support') {
      console.log('hellobolo%');
      this.resetForm()
      this.props.filterCallList({ reset: true })
      console.log(this?.storedData,'zzzzz')
      console.log(this?.storedData?.selectedCOE, 'zzzzz');
      localStorage.setItem("AGENT", JSON.stringify({ "fromD": fromD, "toD": toD, "coe" : this?.storedData?.coe , "selectedCOE":this.storedData?.selectedCOE }))
      this.props?.handleCallViewChange(true)
    }
    if (this.props.componentType == 'AGENT-FILTER') {
      console.log('hellobolo^');
      this.resetForm()
      localStorage.setItem("AGENT", JSON.stringify({ "fromD": `${this.firstDay.getDate()}-${this.firstDay.getMonth() + 1}-${this.firstDay.getFullYear()}`, "toD": `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}` }))
    }
    if (this.props.componentType == 'REPORTS') {
      console.log('hellobolo&');
      this.resetForm()
      console.log(this.state)
      // this.props.filterData({externalId:this.state.coeExternalId,selectedCOE:this.state.selectedCOE,frequency:this.state.frequency,reportType:this.state.reportType})
      //   localStorage.setItem("AGENT",JSON.stringify({"frequency":"MONTHLY",
      //   "reportType":"COE Detail Report",
      //   "fromD":fromD,
      //   "toD": toD,
      //   "selectedDate":this.state?.selectedDate?this.state?.selectedDate:JSON.parse(localStorage.getItem("AGENT"))?.selectedDate,
      //   "selectedRange":this.state?.selectedRange?this.state?.selectedRange:JSON.parse(localStorage.getItem("AGENT"))?.selectedRange,
      //   "coe":this.state?.coeExternalId?this.state?.coeExternalId:JSON.parse(localStorage.getItem("AGENT"))?.coe,
      //   "selectedCOE":this.state.selectedCOE?this.state.selectedCOE:JSON.parse(localStorage.getItem("AGENT"))?.selectedCOE,
      //   "selectedCoeRange":this.state.selectedCoeRange&&this.state.selectedCoeRange.length>0?this.state.selectedCoeRange:JSON.parse(localStorage.getItem("AGENT"))?.selectedCoeRange,
      // }))

    }
  }


  onCreate() {
    this.sidebarObj.element.style.visibility = "";
  }

  toggleClick() {
    this.sidebarObj.toggle();
  }

  setInputs(d) {
    this.setState({ employeeName: d });
  }

  handleChange(e, val) {
    console.log(e.target.name)
    console.log(val)
    // if (typeof(val)!='object'&&e.target.name!='teamEmployeeId'&&e.target.name != "userEmployeeId"&&e.target.name!='accountEmployeeId') {
    //   this.setState(({ data }) => ({
    //     data: { ...data, teamUser: val },
    //   }));
    //   console.log(val)
    //   // val.replace(/[^0-9]+/g, "")
    // } 
    // else {
    if (e.target.name === "teamEmployeeId") {
      e.target.value = e.target.value.replace(/[^A-Za-z0-9]/gi, "");
      this.setState(({ data }) => ({
        data: { ...data, employeeId: e.target.value },
      }));
    }
    else if (e.target.name === "userEmployeeId") {
      this.setState(({ data }) => ({
        data: { ...data, userEmployeeId: e.target.value },
      }));
    }
    else if (e.target.name === 'accountEmployeeId') {
      this.setState(({ data }) => ({
        data: { ...data, accountEmployeeId: e.target.value },
      }));
    }
    else {
      console.log(val)
      this.setState(({ data }) => ({
        data: { ...data, employee: val },
      }));
    }
    // if(e.target.name === 'user'){
    //   e.target.value = e.target.value.replace(/\W/g, "");
    // }
    // if(e.target.name === 'employee'){
    //   e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    // }
  }

  handleTeamUserChange = (e, val) => {
    console.log(val)
    this.setState(({ data }) => ({
      data: { ...data, teamUser: val },
    })); 
  }
  handleAutocompleteKeyDown = (event) => {
    if (event.keyCode === 8) {
      // Backspace key code
      this.setState(({ data }) => ({
        data: { ...data, teamUser: null },
      }));
    }
    if (event.keyCode === 46) {
      // Delete key code
      this.setState(({ data }) => ({
        data: { ...data, teamUser: null },
      }));
    }
  }


  handleUserChange = (e, val) => {
    this.setState(({ data }) => ({
      data: { ...data, user: val },
    }));
    console.log(val)
  }

  handleTeamFromDate = (e) => {
    this.setState({ fromD: e })
    let date = `${((new Date(e)).getDate()) < 10 ? '0' + (new Date(e)).getDate() : (new Date(e)).getDate()}-${((new Date(e)).getMonth() + 1) < 10 ? '0' + ((new Date(e)).getMonth() + 1) : ((new Date(e)).getMonth() + 1)}-${(new Date(e)).getFullYear()}`;
    //setFilteredData({...filteredData,from:date})
    this.setState(({ data }) => ({
      data: { ...data, team_fromD: date, selectedDate: "Custom Date", selectedRange: 9 },
    }));
  }

  handleTeamToDate = (e) => {
    this.setState({ toD: e })
    let date = `${((new Date(e)).getDate()) < 10 ? '0' + (new Date(e)).getDate() : (new Date(e)).getDate()}-${((new Date(e)).getMonth() + 1) < 10 ? '0' + ((new Date(e)).getMonth() + 1) : ((new Date(e)).getMonth() + 1)}-${(new Date(e)).getFullYear()}`;
    //setFilteredData({...filteredData,to:date})
    this.setState(({ data }) => ({
      data: { ...data, team_toD: date, selectedDate: "Custom Date", selectedRange: 9 },
    }));
  }

  handleSelect(e) {
    if (e.target.name == "selectDate") {
      if (e.target.value == "Custom Date") {
        this.myRef.current.style.display = "block";
      } else {
        this.myRef.current.style.display = "none";
      }
    } else {
      this.setState(({ data }) => ({
        data: { ...data, [e.target.name]: e.target.value },
      }));
    }
  }

  displayDatePicker() {
    return (
      <div>
        <p className="mb-0 call-fillter-heading">From:</p>
        <hr className="filter-Hr"></hr>
        <DatePicker
          selected={this.startDate}
          onChange={this.handleChange}
          name="startDate"
          dateFormat="MM/dd/yyyy"
        />

        <p className="mb-0 call-fillter-heading">To:</p>
        <hr className="filter-Hr"></hr>
        <DatePicker
          selected={this.startDate}
          onChange={this.handleChange}
          name="startDate"
          dateFormat="MM/dd/yyyy"
        />
      </div>
    );
  }

  pitchLog = (e) => {
    // this.setState({ pitchScore: e.target.value });
    this.setState(({ data }) => ({
      data: { ...data, pitch: e.target.value },
    }));
  };

  callLog = (e) => {
    // this.setState({ callScore: e.target.value });
    this.setState(({ data }) => ({
      data: { ...data, call: e.target.value },
    }));
  };

  designationChange = (val) => {
    this.setState(({ data }) => ({
      data: { ...data, designation: val.target.textContent },
    }));
  };

  roleChange = (val) => {
    this.setState(({ data }) => ({
      data: { ...data, role: val.target.textContent },
    }));
  };

  coeChange = (e) => {
    console.log(e.target.value)
    let coe = this.props.coes.filter(item => { return item.coeName == e.target.value })
    let coeName = this.props.coes.map(item => { return item.coeName })
    let ids = this.props.coes.map(item => { return item.coeId })
    let externalId = this.props.coes.map(item => { return item.externalId })
    console.log(coe)
    this.setState(({ data }) => ({
      data: { ...data, teamCOE: e.target.value == 'Select All' ? coeName : [e.target.value], coeId: e.target.value == 'Select All' ? ids : [coe[0].coeId] },
    }));
    this.setState({ selectedCOE: e.target.value == 'Select All' ? coeName : [e.target.value] })
    this.setState({ selectedCoeRange: e.target.value == 'Select All' ? ids : [coe[0].coeId] })
    this.setState({ coeExternalId: e.target.value == 'Select All' ? externalId : [coe[0].externalId] })
  }

  handleChangeCoe = (e, val) => {
    console.log(val)
    this.setState(({ data }) => ({
      data: { ...data, coe: val.label, userCoeId: val.value },
    }));
  };

  callerChange = (val) => {
    console.log(val.target.textContent)
    this.setState(({ data }) => ({
      data: { ...data, caller: val.target.textContent },
    }));
  };

  managerChange = (e, val) => {
    console.log(val)
    this.setState(({ data }) => ({
      data: { ...data, manager: val.label, managerId: val.value },
    }));
  };

  ahtChange = (e) => {
    // this.setState({ ahtScore: e.target.value });
    this.setState(({ data }) => ({
      data: { ...data, aht: e.target.value },
    }));
  };

  callsFromChange = (e, val) => {
    //this.setState({ value: e.target.value.replace(/[^\w\s]/gi, "") });
    if (val) {
      this.setState(({ data }) => ({
        data: { ...data, callsFrom: e.target.value },
      }));
    } else {
      this.setState(({ data }) => ({
        data: {
          ...data,
          [e.target.name]: e.target.value.replace(/[^\w\s]/gi, ""),
        },
      }));
    }
  };

  callsToChange = (e, val) => {
    if (val) {
      this.setState(({ data }) => ({
        data: { ...data, callsTo: val },
      }));
    } else {
      this.setState(({ data }) => ({
        data: { ...data, [e.target.name]: e.target.value.replace(/[^\w\s]/gi, "") },
      }));
    }
  };


  callBack = (e) => {
    this.setState({ dashboard_fromD: e.fromDate })
    this.setState({ dashboard_toD: e.toDate })
    this.setState({ coeExternalId: e.externalId })
    this.setState({ selectedCOE: e.selectedCOE })
    this.setState({ selectedCoeRange: e.selectedCoeRange })
    this.setState({ selectedDate: e.selectedDate })
    this.setState({ selectedRange: e.selectedRange })
    this.setState({ momentBucket: e.momentBucket })
    this.setState({ momentBucketId: e.momentBucketId })
  }

  defaultCallFilter = (e) => {
    this.props?.setCoe(e?.selectedCoeRange)
    localStorage.setItem("AGENT", JSON.stringify(
      {
        "pageSelected": this.storedData?.pageSelected,
        "analysis": this.storedData?.analysis,
        "productivity": this.storedData?.productivity,
        "fromD": this.storedData?.fromD ? this.storedData?.fromD : e.fromDate,
        "toD": this.storedData?.toD ? this.storedData?.toD : e.toDate,
        "agentDetailCOE": this.storedData?.agentDetailCOE,
        "coe": this.storedData?.coe,
        "selectedCOE": this.storedData?.selectedCOE ? this.storedData?.selectedCOE : e.selectedCOE,
        "selectedCoeRange": this.storedData?.selectedCoeRange,
        "userIds": this.storedData?.userIds,
        "userNames": this.storedData?.userNames,
        "empId": this.storedData?.employeeId,
        "empName": this.storedData?.user,
        "role": this.storedData?.role,
        "designation": this.storedData?.designation,
        "type": this.storedData?.type,
        "teamCOE": this.storedData?.teamCOE,
        "callsFrom": this.storedData?.callsFrom,
        "callsTo": this.storedData?.callsTo,
        "aht": this.storedData?.aht,
        "callScore": this.storedData?.callScore,
        "pitchScore": this.storedData?.pitchScore,
        "customerName":this.storedData?.customerName,
        "customerMobile":this.storedData?.customerMobile,
        "fileName": this.storedData?.fileName,
        "sellerROS": this.storedData?.sellerROS,
        "sellerName": this.storedData?.sellerName,
        "selectedSeller": this.storedData?.selectedSeller,
        "cScore": this.storedData?.cScore,
        "callHealth": this.storedData?.callHealth,
        "callDuration": this.storedData?.callDuration,
        "words": this.storedData?.words,
        "moment": this.storedData?.moment,
        "momentId": this.storedData?.momentId,
        "pitch": this.storedData?.pitch,
        "prob": this.storedData?.prob,
        "opportunity": this.storedData?.opp,
        "urgency": this.storedData?.urgency,
        "selectedDate": this.storedData?.selectedDate,
        "selectedRange": this.storedData?.selectedRange,
        "momentBucket": this.storedData?.momentBucket,
        "momentBucketId": this.storedData?.momentBucketId,
        "frequency": this.storedData?.frequency,
        "reportType": this.storedData?.reportType
      }))
    this.props.defaultConvFilter({ from: e.fromDate, to: e.toDate })
  }

  callBackFunc = (e) => {
    console.log(e)
    this.setState({ Cname: e.customerName })
    this.setState({ mobile: e.customerMobile })
    this.setState({ file: e.fileName })
    this.setState({ Sname: e.agentName })
    this.setState({ agentExId: e.agentExId })
    this.setState({ selectedS: e.selectedSeller })
    this.setState({ Sros: e.sellerROS })
    this.setState({ Cscore: e.callScoreRange })
    this.setState({ health: e.callHealth })
    this.setState({ CallDur: e.callDurationRange })
    this.setState({ from: e.from })
    this.setState({ to: e.to })
    this.setState({ words: e.words })
    this.setState({ moment: e.moment })
    this.setState({ momentId: e.momentId })
    this.setState({ coe: e.coeName })
    this.setState({ selectedCoeRange: e.selectedCoeRange })
    this.setState({ coeExternalId: e.externalId })
    this.setState({ pitch: e.pitch })
    this.setState({ prob: e.prob })
    this.setState({ opp: e.opp })
    this.setState({ urgency: e.urgency })
    this.setState({ selectedDate: e.selectedDate })
    this.setState({ selectedRange: e.selectedRange })
  }

  agentsFilter = (e) => { 
    this.setState({ coeId: e.externalId })
    this.setState({ fromD: e.fromDate })
    this.setState({ toD: e.toDate })
    this.setState({ defCoe: e.externalId })
    this.setState({ defFrom: e.fromDate })
    this.setState({ defTo: e.toDate })
    this.setState({ selectedRange: e.selectedRange })
    this.setState({ selectedDate: e.selectedDate })

  }

  defaultData = (e) => {
    this.setState({ defaultAgentCoe: e.externalId })
    localStorage.setItem("AGENT", JSON.stringify(
      {
        "pageSelected": this.storedData?.pageSelected,
        "analysis": this.storedData?.analysis,
        "productivity": this.storedData?.productivity,
        "fromD": this.storedData?.fromD ? this.storedData?.fromD : e.fromDate,
        "toD": this.storedData?.toD ? this.storedData?.toD : e.toDate,
        "agentDetailCOE": this.storedData?.agentDetailCOE ? this.storedData.agentDetailCOE : e.externalId,
        "coe": this.storedData?.coe,
        "selectedCOE": this.storedData?.selectedCOE,
        "selectedCoeRange": this.storedData?.selectedCoeRange,
        "userIds": this.storedData?.userIds,
        "userNames": this.storedData?.userNames,
        "empId": this.storedData?.employeeId,
        "empName": this.storedData?.user,
        "role": this.storedData?.role,
        "designation": this.storedData?.designation,
        "type": this.storedData?.type,
        "teamCOE": this.storedData?.teamCOE,
        "callsFrom": this.storedData?.callsFrom,
        "callsTo": this.storedData?.callsTo,
        "aht": this.storedData?.aht,
        "callScore": this.storedData?.callScore,
        "pitchScore": this.storedData?.pitchScore,
        "customerName": this.storedData?.customerName,
        "customerMobile": this.storedData?.customerMobile,
        "fileName": this.storedData?.fileName,
        "sellerROS": this.storedData?.sellerROS,
        "sellerName": this.storedData?.sellerName,
        "selectedSeller": this.storedData?.selectedSeller,
        "cScore": this.storedData?.cScore,
        "callHealth": this.storedData?.callHealth,
        "callDuration": this.storedData?.callDuration,
        "words": this.storedData?.words,
        "moment": this.storedData?.moment,
        "momentId": this.storedData?.momentId,
        "pitch": this.storedData?.pitch,
        "prob": this.storedData?.prob,
        "opportunity": this.storedData?.opp,
        "urgency": this.storedData?.urgency,
        "selectedDate": this.storedData?.selectedDate,
        "selectedRange": this.storedData?.selectedRange,
        "momentBucket": this.storedData?.momentBucket,
        "momentBucketId": this.storedData?.momentBucketId,
        "frequency": this.storedData?.frequency,
        "reportType": this.storedData?.reportType
      }))
    this.props.defaultFilter({ coeIds: e.externalId, from: e.fromDate, to: e.toDate })
  }

  defaultDashboardData = (e) => {
    this.props?.setCoe(e?.selectedCoeRange)
    localStorage.setItem("AGENT", JSON.stringify(
      {
        "pageSelected": this.storedData?.pageSelected,
        "analysis": this.storedData?.analysis,
        "productivity": this.storedData?.productivity,
        "fromD": e.fromDate,
        "toD": e.toDate,
        "agentDetailCOE": this.storedData?.agentDetailCOE,
        "coe": e.externalId,
        "selectedCOE": e.selectedCOE,
        "selectedCoeRange": e.selectedCoeRange ? e.selectedCoeRange : this.storedData.selectedCoeRange,
        "userIds": this.storedData?.userIds,
        "userNames": this.storedData?.userNames,
        "empId": this.storedData?.employeeId,
        "empName": this.storedData?.user,
        "role": this.storedData?.role,
        "designation": this.storedData?.designation,
        "type": this.storedData?.type,
        "teamCOE": this.storedData?.teamCOE,
        "callsFrom": this.storedData?.callsFrom,
        "callsTo": this.storedData?.callsTo,
        "aht": this.storedData?.aht,
        "callScore": this.storedData?.callScore,
        "pitchScore": this.storedData?.pitchScore,
        "customerName": this.storedData?.customerName,
        "customerMobile": this.storedData?.customerMobile,
        "fileName": this.storedData?.fileName,
        "sellerROS": this.storedData?.sellerROS,
        "sellerName": this.storedData?.sellerName,
        "selectedSeller": this.storedData?.selectedSeller,
        "cScore": this.storedData?.cScore,
        "callHealth": this.storedData?.callHealth,
        "callDuration": this.storedData?.callDuration,
        "words": this.storedData?.words,
        "moment": this.storedData?.moment,
        "momentId": this.storedData?.momentId,
        "pitch": this.storedData?.pitch,
        "prob": this.storedData?.prob,
        "opportunity": this.storedData?.opp,
        "urgency": this.storedData?.urgency,
        "selectedDate": e.selectedDate,
        "selectedRange": e.selectedRange,
        "momentBucket": e.momentBucket,
        "momentBucketId": e.momentBucketId,
        "frequency": this.storedData?.frequency,
        "reportType": this.storedData?.reportType
      }))
    // if(e.fromDate!=undefined&&e.toDate!=undefined){
    this.props.beforeFilter({ from: e.fromDate, to: e.toDate, externalIds: e.externalId, momentBucket: e.momentBucket, selectedCOE: e.selectedCOE, selectedDate: e.selectedDate, loader: true })
    // }

  }

  defaultReportData = (e) => {
    console.log(e)
    // this.props.defaultFilterData({externalId:e.externalId,selectedCOE:e.selectedCOE,frequency:e.frequencySelected,reportType:e.reportType,selectedCOERange:e.selectedCOERange})
    localStorage.setItem("AGENT", JSON.stringify(
      {
        "pageSelected": this.storedData?.pageSelected,
        "analysis": this.storedData?.analysis,
        "productivity": this.storedData?.productivity,
        "fromD": e?.fromDate,
        "toD": e?.toDate,
        "agentDetailCOE": this.storedData?.agentDetailCOE,
        "coe": e?.externalId,
        "selectedCOE": e?.selectedCOE,
        "selectedCoeRange": e.selectedCOERange ? e.selectedCOERange : this.storedData?.selectedCoeRange,
        "userIds": this.storedData?.userIds,
        "userNames": this.storedData?.userNames,
        "empId": this.storedData?.employeeId,
        "empName": this.storedData?.user,
        "role": this.storedData?.role,
        "designation": this.storedData?.designation,
        "type": this.storedData?.type,
        "teamCOE": this.storedData?.teamCOE,
        "callsFrom": this.storedData?.callsFrom,
        "callsTo": this.storedData?.callsTo,
        "aht": this.storedData?.aht,
        "callScore": this.storedData?.callScore,
        "pitchScore": this.storedData?.pitchScore,
        "customerName": this.storedData?.customerName,
        "customerMobile": this.storedData?.customerMobile,
        "fileName": this.storedData?.fileName,
        "sellerROS": this.storedData?.sellerROS,
        "sellerName": this.storedData?.sellerName,
        "selectedSeller": this.storedData?.selectedSeller,
        "cScore": this.storedData?.cScore,
        "callHealth": this.storedData?.callHealth,
        "callDuration": this.storedData?.callDuration,
        "words": this.storedData?.words,
        "moment": this.storedData?.moment,
        "moment": this.storedData?.momentId,
        "pitch": this.storedData?.pitch,
        "prob": this.storedData?.prob,
        "opportunity": this.storedData?.opp,
        "urgency": this.storedData?.urgency,
        "selectedDate": e?.selectedDate,
        "selectedRange": e?.selectedRange,
        "momentBucket": this.storedData?.momentBucket,
        "momentBucketId": this.storedData?.momentBucketId,
        "frequency": e.frequency,
        "reportType": e.reportType
      }
    ))
    this.props.filterData({ externalId: e?.externalId, selectedCOE: e?.selectedCOE, frequency: e?.frequency, reportType: e?.reportType, selectedCOE: e?.selectedCOE, selectedCOERange: e?.selectedCoeRange })
  }

  reportFilter = (e) => {
    console.log(e)
    this.setState({ frequency: e.frequencySelected })
    this.setState({ reportType: e.reportType })
    this.setState({ coeExternalId: e.externalId })
    this.setState({ selectedCOE: e.selectedCOE })
    this.setState({ selectedCoeRange: e.selectedCOERange })
    this.setState({ fromD: e.fromDate })
    this.setState({ toD: e.toDate })
    this.setState({ selectedRange: e.selectedRange })
    this.setState({ selectedDate: e.selectedDate })
    if (e.reset == true) {
      // this.props.defaultFilterData({externalId:e.externalId,selectedCOE:e.selectedCOE,frequency:e.frequencySelected,reportType:e.reportType,selectedCOERange:e.selectedCOERange})
      localStorage.setItem("AGENT", JSON.stringify(
        {
          "pageSelected": this.storedData?.pageSelected,
          "analysis": this.storedData?.analysis,
          "productivity": this.storedData?.productivity,
          "fromD": e?.fromDate,
          "toD": e?.toDate,
          "agentDetailCOE": this.storedData?.agentDetailCOE,
          "coe": [e?.externalId],
          "selectedCOE": e?.selectedCOE,
          "selectedCoeRange": e?.selectedCOERange,
          "userIds": this.storedData?.userIds,
          "userNames": this.storedData?.userNames,
          "empId": this.storedData?.employeeId,
          "empName": this.storedData?.user,
          "role": this.storedData?.role,
          "designation": this.storedData?.designation,
          "type": this.storedData?.type,
          "teamCOE": this.storedData?.teamCOE,
          "callsFrom": this.storedData?.callsFrom,
          "callsTo": this.storedData?.callsTo,
          "aht": this.storedData?.aht,
          "callScore": this.storedData?.callScore,
          "pitchScore": this.storedData?.pitchScore,
          "customerName": this.storedData?.customerName,
          "customerMobile": this.storedData?.customerMobile,
          "fileName": this.storedData?.fileName,
          "sellerROS": this.storedData?.sellerROS,
          "sellerName": this.storedData?.sellerName,
          "selectedSeller": this.storedData?.selectedSeller,
          "cScore": this.storedData?.cScore,
          "callHealth": this.storedData?.callHealth,
          "callDuration": this.storedData?.callDuration,
          "words": this.storedData?.words,
          "moment": this.storedData?.moment,
          "moment": this.storedData?.momentId,
          "pitch": this.storedData?.pitch,
          "prob": this.storedData?.prob,
          "opportunity": this.storedData?.opp,
          "urgency": this.storedData?.urgency,
          "selectedDate": e?.selectedDate,
          "selectedRange": e?.selectedRange,
          "momentBucket": this.storedData?.momentBucket,
          "momentBucketId": this.storedData?.momentBucketId,
          "frequency": e.frequency,
          "reportType": e.reportType
        }
      ))
      this.props.filterData({ externalId: e?.externalId, selectedCOE: e?.selectedCOE, selectedCOERange: e?.selectedCoeRange, frequency: e?.frequency, reportType: e?.reportType, selectedDate: e?.selectedDate, selectedRange: e?.selectedRange })
    }
    // this.props.defaultFilterData({externalId:e.externalId,selectedCOE:e.selectedCOE,frequency:e.frequencySelected,reportType:e.reportType,selectedCOERange:e.selectedCOERange})
    // localStorage.setItem("AGENT",JSON.stringify(
    //   {
    //   "pageSelected":this.storedData?.pageSelected,
    //   "analysis":this.storedData?.analysis,
    //   "productivity":this.storedData?.productivity,
    //   "fromD":this.storedData?.fromD?this.storedData?.fromD:e.fromDate,
    //   "toD":this.storedData?.toD?this.storedData?.toD:e?.toDate,
    //   "agentDetailCOE":this.storedData?.agentDetailCOE,
    //   "coe":this.storedData?.coe?this.storedData?.coe:e.externalId,
    //   "selectedCOE":e.selectedCOE,
    //   "selectedCoeRange":this.storedData?.selectedCoeRange?this.storedData?.selectedCoeRange:e.selectedCOERange,
    //   "userIds":this.storedData?.userIds,
    //   "userNames":this.storedData?.userNames,
    //   "empId":this.storedData?.employeeId,
    //   "empName":this.storedData?.user,
    //   "role":this.storedData?.role,
    //   "designation":this.storedData?.designation,
    //   "type":this.storedData?.type,
    //   "teamCOE":this.storedData?.teamCOE,
    //   "callsFrom":this.storedData?.callsFrom,
    //   "callsTo":this.storedData?.callsTo,
    //   "aht":this.storedData?.aht,
    //   "callScore":this.storedData?.callScore,
    //   "pitchScore":this.storedData?.pitchScore,
    //   "customerName":this.storedData?.customerName,
    //   "customerMobile":this.storedData?.customerMobile,
    //   "fileName":this.storedData?.fileName,
    //   "sellerROS":this.storedData?.sellerROS,
    //   "sellerName":this.storedData?.sellerName,
    //   "selectedSeller":this.storedData?.selectedSeller,
    //   "cScore":this.storedData?.cScore,
    //   "callHealth":this.storedData?.callHealth,
    //   "callDuration":this.storedData?.callDuration,
    //   "words":this.storedData?.words,
    //   "moment":this.storedData?.moment,
    //   "moment":this.storedData?.momentId,
    //   "pitch":this.storedData?.pitch,
    //   "prob":this.storedData?.prob,
    //   "opportunity":this.storedData?.opp,
    //   "urgency":this.storedData?.urgency,
    //   "selectedDate":this.storedData?.selectedDate,
    //   "selectedRange":this.storedData?.selectedRange,
    //   "momentBucket":this.storedData?.momentBucket,
    //   "momentBucketId":this.storedData?.momentBucketId,
    //   "frequency":e.frequency,
    //   "reportType":e.reportType
    //   }
    // ))
  }

  displayFilter() {
    switch (this.props.componentType) {
      case "FILTER USERS": {
        var arr = this.props.users.map((val) => val.designation);
        arr = arr.filter(function (designation) {
          return designation != undefined;
        });
        var uniq = [...new Set(arr)];

        var managerList = this.props.users.map((val) => ({ label: val.manager, value: val.managerId }));
        managerList = managerList.filter(function (manager) {
          return manager.label != undefined && manager.value != undefined
        });

        var unique = managerList.reduce((key, value) => {
          if (!key.some(item => item.label === value.label)) {
            key.push(value);
          }
          return key;
        }, []);
        console.log(unique)

        let coeDropdown = this?.props?.coeOptions?.map(item => { return { label: item?.coeName, value: item?.coeId } })

        var users = this.props.users.map(
          (val, userId) => { return { label: val.firstName + `${val.lastName ? " " : ""}` + val.lastName, value: val.firstName + `${val.lastName ? " " : ""}` + val.lastName, id: userId } }
        );
        console.log(users)

        return (
          <div>
            <div className="mb-3 remove-m">
              <p className="mb-0 call-fillter-heading">Employee Id</p>
              <hr className="filter-Hr"></hr>
              <input
                type="text"
                name="userEmployeeId"
                value={this.state.data.userEmployeeId}
                className="form-control form-control-sm mb-3 py-1"
                placeholder="Employee Id"
                onChange={this.handleChange}
              />
            </div>
            <div className="mb-3 remove-m small-dropdown">
              <p className="mb-0 call-fillter-heading">Employee</p>
              <hr className="filter-Hr"></hr>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                // name="employee"
                options={users}
                disableClearable
                value={this.state.data.user}
                onChange={this.handleUserChange}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="commonFont2"
                    value={this.state.data.user}
                    placeholder="Employee"
                    // onChange={this.handleChange}
                    label=""
                  />
                )}
              />
            </div>
            <div className="mb-3 remove-m small-dropdown">
              <p className="mb-0 call-fillter-heading">Role</p>
              <hr className="filter-Hr"></hr>
              <div className="mui-selectBox rollStyle">
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="combo-box-demo"
                  options={this.props.roles.map(item => { return item.roleName })}
                  value={this.state.data.role}
                  onChange={this.roleChange}
                  renderInput={(params) => (
                    <TextField
                      className="commonFont2"
                      name="role"
                      {...params}
                      value={this.state.data.role}
                      placeholder="Role"
                      users
                      label=""
                    />
                  )}
                />

                {/* <Select id="inputRole" className="w-100" displayEmpty value={this.state.data.role} onChange={this.handleSelect} name="role">
                      <MenuItem value=' 'disabled>Select</MenuItem>
                      <MenuItem  value="ADMIN">ADMIN</MenuItem>
                      <MenuItem  value="AGENT">AGENT</MenuItem>
                  </Select> */}
              </div>
            </div>
            <div className="mb-3 remove-m small-dropdown">
              <p className="mb-0 call-fillter-heading">COE</p>
              <hr className="filter-Hr"></hr>
              <Autocomplete
                disablePortal
                disableClearable
                id="combo-box-demo"
                options={coeDropdown}
                // getOptionLabel={(option)=> option.coeName}
                value={this.state.data.coe}
                onChange={this.handleChangeCoe}
                renderInput={(params) => (
                  <TextField
                    className="commonFont2"
                    name="coe"
                    {...params}
                    value={this.state.data.coe}
                    placeholder="COE"
                    label=""
                  />
                )}
              />
            </div>
            <div className="mb-3 remove-m small-dropdown">
              <p className="mb-0 call-fillter-heading">Designation</p>
              <hr className="filter-Hr"></hr>
              <Autocomplete
                disablePortal
                disableClearable
                id="designation"
                options={uniq}
                value={this.state.data.designation}
                onChange={this.designationChange}
                renderInput={(params) => (
                  <TextField
                    className="commonFont2"
                    {...params}
                    value={this.state.data.designation}
                    placeholder="Designation"
                    label=""
                  />
                )}
              />
            </div>
            <div className="mb-3 remove-m small-dropdown">
              <p className="mb-0 call-fillter-heading">Caller</p>
              <hr className="filter-Hr"></hr>
              <div className="mui-selectBox rollStyle">
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="combo-box-demo"
                  options={["Caller", "Non Caller"]}
                  value={this.state.data.caller}
                  onChange={this.callerChange}
                  renderInput={(params) => (
                    <TextField
                      className="commonFont2"
                      name="caller"
                      {...params}
                      value={this.state.data.caller}
                      placeholder="Caller"
                      label=""
                    />
                  )}
                />
                {/* <Select id="inputRole" className="w-100" value={this.state.data.caller} onChange={this.handleSelect} name="caller">
                      <MenuItem value=' 'disabled> Select</MenuItem>
                      <MenuItem  value="true">Caller</MenuItem>
                      <MenuItem  value="false">Non Caller</MenuItem>
                  </Select> */}
              </div>
            </div>
            <div className="mb-3 remove-m small-dropdown">
              <p className="mb-0 call-fillter-heading">Manager</p>
              <hr className="filter-Hr"></hr>
              <Autocomplete
                disablePortal
                disableClearable
                id="combo-box"
                options={unique}
                value={this.state.data.manager}
                onChange={this.managerChange}
                renderInput={(params) => (
                  <TextField
                    className="commonFont2"
                    {...params}
                    value={this.state.data.manager}
                    placeholder="Manager"
                    label=""
                  />
                )}
              />
            </div>
          </div>
        );
      }
      case "Dashboard-Sales": {
        return (
          <DashboardFilters filterInput={this.props.filterInput} callBack={this.callBack} name={this.props.name} reset={this.state.resetField} resetFormState={this.resetFormState} defaultDashboardData={this.defaultDashboardData} disableApply={this.disableDashBoardApply}/>
        );
      }

      case "Dashboard-Support": { 
        return (
          <DashboardFilters filterInput={this.props.filterInput} callBack={this.callBack} name={this.props.name} reset={this.state.resetField} resetFormState={this.resetFormState} defaultDashboardData={this.defaultDashboardData} />
        );
      }

      case "AGENT-FILTER": {
        return (
          <AgentFilter agentData={this.props.agentData} agentsFilter={this.agentsFilter} reset={this.state.resetField} resetFormState={this.resetFormState} defaultData={this.defaultData} />
        )
      }


      case "Conversation-Sales": {
        return (
          <ConvFilter callBackFunc={this.callBackFunc} reset={this.state.resetField} resetFormState={this.resetFormState} name={this.props.name} defaultCallFilter={this.defaultCallFilter} historyObject={this.props?.historyObject}/>
        );
      }

      case "Conversation-Support": {
        return (
          <ConvFilter callBackFunc={this.callBackFunc} reset={this.state.resetField} resetFormState={this.resetFormState} name={this.props.name} defaultCallFilter={this.defaultCallFilter} />
        );
      }

      case "FILTER TEAM": {
        var arr = this.props.team.map((val) => val.designation);
        arr = arr.filter(function (designation) {
          return designation != undefined;
        });
        var uniq = [...new Set(arr)];

        var users = this.props.team.map(
          (val, userId) => { return { label: val.firstName + `${val.lastName ? " " : ""}` + val.lastName, value: val.firstName + `${val.lastName ? " " : ""}` + val.lastName, id: userId } }
        );

        return (
          <>
            <div className="mb-3">
              <p className="mb-0 call-fillter-heading">Employee Id</p>
              <hr className="filter-Hr"></hr>
              <input
                type="text"
                name="accountEmployeeId"
                value={this.state.data.accountEmployeeId}
                className="form-control form-control-sm mb-3 py-1"
                id="inputEmployeeName"
                placeholder="Employee Id"
                onChange={this.handleChange}
              />
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">Employee</p>
              <hr className="filter-Hr"></hr>
              {/* <input type="text" name="user"  value={this.state.data.user} className="form-control form-control-sm mb-3 py-1" id="inputEmployeeName" placeholder="Employee Name"  onChange={ this.handleChange}  /> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={users}
                disableClearable
                value={this.state.data.employee}
                onChange={this.handleChange}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="commonFont2"
                    name="employee"
                    value={this.state.data.employee}
                    placeholder="User"
                    label=""
                  />
                )}
              />
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">Role</p>
              <hr className="filter-Hr"></hr>
              <div className="mui-selectBox">
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="combo-box-demo"
                  options={this.props.roles.map(item => { return item.roleName })}
                  value={this.state.data.role}
                  onChange={this.roleChange}
                  renderInput={(params) => (
                    <TextField
                      className="commonFont2"
                      name="role"
                      {...params}
                      value={this.state.data.role}
                      placeholder="Role"
                      label=""
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">Designation</p>
              <hr className="filter-Hr"></hr>
              <Autocomplete
                disablePortal
                id="designation"
                options={uniq}
                disableClearable
                value={this.state.data.designation}
                onChange={this.designationChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="commonFont2"
                    value={this.state.data.designation}
                    placeholder="Designation"
                    label=""
                  />
                )}
              />
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">Employee Type</p>
              <hr className="filter-Hr"></hr>
              <div className="mui-selectBox">
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="combo-box-demo"
                  options={["Caller", "Non Caller"]}
                  value={this.state.data.caller}
                  onChange={this.callerChange}
                  renderInput={(params) => (
                    <TextField
                      className="commonFont2"
                      name="caller"
                      {...params}
                      value={this.state.data.caller}
                      placeholder="Caller"
                      label=""
                    />
                  )}
                />
              </div>
            </div>
            {/* <div className="mb-3">
                <label>Call Score</label>
                <div className="w-100 ps-2 pe-3">
                  <Box sx={{ width: 200 }}>
                    <Slider 
                      value={this.state.callScore}
                      onChange={this.callLog}
                      valueLabelDisplay="auto"
                      marks={this.marks}
                      disableSwap
                      name='call'
                    />
                  </Box>
                </div>
              </div>
              <div className="mb-3">
                <label>Pitch Score</label>
                <div className="w-100 ps-2 pe-3">
                   <Box sx={{ width: 200 }}>
                      <Slider
                        value={this.state.pitchScore}
                        onChange={this.pitchLog}
                        valueLabelDisplay="auto"
                        marks={this.marks}
                        disableSwap
                        name='pitch'
                      />
                    </Box>
                </div>
              </div> */}
          </>
        );
      }

      case "FILTER TEAM_TAB": {
        let arr = this.props.Team?.map((val) => val.designation);
        arr = arr?.filter(function (designation) {
          return designation != undefined;
        });
        const uniq = [...new Set(arr)];

        const users = this.props.Team?.map(
          (val, userId) => { return { label: val.firstName + `${val.lastName ? " " : ""}` + val.lastName, value: val.firstName + `${val.lastName ? " " : ""}` + val.lastName, id: userId } }
        );

        return (
          <>
            <div className="mb-3">
              <p className="mb-0 call-fillter-heading">Employee Id</p>
              <hr className="filter-Hr"></hr>
              <input
                type="text"
                name="teamEmployeeId"
                value={this.state.data.employeeId}
                defaultValue="mydefault"
                className="form-control form-control-sm mb-3 py-1"
                id="inputEmployeeName"
                placeholder="Employee Id"
                onChange={this.handleChange}
              />
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">Employee</p>
              <hr className="filter-Hr"></hr>
              {/* <input type="text" name="user"  value={this.state.data.user} className="form-control form-control-sm mb-3 py-1" id="inputEmployeeName" placeholder="Employee Name"  onChange={ this.handleChange}  /> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={users ? users : []}
                disableClearable
                value={this.state.data.teamUser}
                onKeyDown={this.handleAutocompleteKeyDown}
                // name="user"
                onChange={this.handleTeamUserChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="commonFont2"
                    // name = "user"
                    value={this.state.data.teamUser}
                    // onChange={this.handleChange}
                    placeholder="User"
                    label=""
                  />
                )}
              />
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">Role</p>
              <hr className="filter-Hr"></hr>
              <div className="mui-selectBox">
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="combo-box-demo"
                  options={this.props.roles.map(item => { return item.roleName })}
                  value={this.state.data.role}
                  onChange={this.roleChange}
                  renderInput={(params) => (
                    <TextField
                      className="commonFont2"
                      name="role"
                      {...params}
                      value={this.state.data.role}
                      placeholder="Role"
                      label=""
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">Designation</p>
              <hr className="filter-Hr"></hr>
              <Autocomplete
                disablePortal
                id="designation"
                options={uniq}
                disableClearable
                value={this.state.data.designation}
                onChange={this.designationChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="commonFont2"
                    value={this.state.data.designation}
                    placeholder="Designation"
                    label=""
                  />
                )}
              />
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">Employee Type</p>
              <hr className="filter-Hr"></hr>
              <div className="mui-selectBox">
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="combo-box-demo"
                  options={["Caller", "Non Caller"]}
                  value={this.state.data.caller}
                  onChange={this.callerChange}
                  renderInput={(params) => (
                    <TextField
                      className="commonFont2"
                      name="caller"
                      {...params}
                      value={this.state.data.caller}
                      placeholder="Caller"
                      label=""
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-3 small-dropdown">
              <p className="mb-0 call-fillter-heading">COE</p>
              <hr className="filter-Hr"></hr>
              <div className="mui-selectBox">
                <Form.Select as="select" custom
                  style={{ textTransform: "capitalize" }}
                  name="coe" aria-label="Default select example"
                  //ref={coeRef}
                  onChange={this.coeChange}
                  className="form-control-sm py-1"
                  placeholder="Select COE"
                  value={this.state.data.teamCOE}
                >
                  <option value={'Select All'}>Select All</option>
                  {this.props.coes.map((coe) => {
                    return <option value={coe.coeName} key={coe.coeId}>{coe.coeName}</option>;
                  })}
                </Form.Select>
                {/* <Autocomplete
                  disablePortal
                  disableClearable
                  id="combo-box-demo" 
                  options={this.props.coes.map(item=>{return item.coeName})}
                  value={this.state.data.teamCOE?this.state.data.teamCOE:this.storedData?.selectedCOE[0]}
                  onChange={this.coeChange}
                  renderInput={(params) => (
                    <TextField
                      className="commonFont2"
                      name="coe"
                      {...params}
                      value={this.state.data.teamCOE}
                      placeholder="COE"
                      label=""
                    />
                  )}
                /> */}
              </div>
            </div>
            <div className="mb-3">
              <p className="mb-0 call-fillter-heading">Number of Calls</p>
              <hr className="filter-Hr"></hr>
              <div className="mui-selectBox d-flex">
                <input
                  type="number"
                  name="callsFrom"
                  value={parseInt(this.state.data.callsFrom)}
                  className="form-control form-control-sm mb-3 py-1 me-2"
                  id="inputFrom"
                  placeholder="Calls From"
                  onChange={this.callsFromChange}
                />
                <input
                  type="number"
                  name="callsTo"
                  value={parseInt(this.state.data.callsTo)}
                  className="form-control form-control-sm mb-3 py-1"
                  id="inputTo"
                  placeholder="Calls To"
                  min={0}
                  onChange={this.callsToChange}
                />
              </div>
              <span>
                {" "}
                {this.state.errorMessage && (
                  <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                )}
              </span>
            </div>
            <div className="mb-3">
              <p className="mb-1 call-fillter-heading">
                Call Date{" "}
              </p>
              <hr className="filter-Hr"></hr>
              <div className="call-fillter-date dasbard-comp-setting mb-3">
                <div className="input-box form-group">
                  <label className="col-form-label ms-1">From:</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      openTo="day"
                      views={['year', 'month', 'day']}
                      inputFormat={this.dateFormat}
                      value={this.state.fromD}
                      name="from"
                      onChange={this.handleTeamFromDate}
                      disableOpenPicker
                      open={this.state.opendp}
                      onOpen={() => this.setState({ opendp: true })}
                      onClose={() => this.setState({ opendp: false })}
                      renderInput={(params) => (
                        <TextField {...params} onClick={(e) => this.setState({ opendp: true })} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="input-box form-group">
                  <label className="col-form-label ms-1">To:</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      openTo="day"
                      views={['year', 'month', 'day']}
                      inputFormat={this.dateFormat}
                      value={this.state.toD}
                      name="to"
                      onChange={this.handleTeamToDate}
                      disableOpenPicker
                      open={this.state.opendp1}
                      onOpen={() => this.setState({ opendp1: true })}
                      onClose={() => this.setState({ opendp1: false })}
                      renderInput={(params) => (
                        <TextField {...params} onClick={(e) => this.setState({ opendp1: true })} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <></>
            <div className="mb-3">
              <p className="mb-0 call-fillter-heading">
                AHT{" "}
                {`(${this.state.data.aht[0]} - ${this.state.data.aht[1]}) min.  `}
              </p>
              <hr className="filter-Hr"></hr>
              <div className="w-100 range-slider">
                {/* <p className="ml-2">{`(${this.state.ahtScore[0]}-${this.state.ahtScore[1]})`}</p> */}
                <Box>
                  <Slider
                    value={this.state.data.aht}
                    onChange={this.ahtChange}
                    // defaultValue={20}
                    className="aht"
                    valueLabelDisplay="auto"
                    marks={this.ahTScore}
                    // disableSwap
                    // step={0.1}
                    min={0}
                    max={300}
                    name="aht"
                  />
                </Box>
              </div>
            </div>
            <div className="mb-3">
              <p className="mb-0 call-fillter-heading">
                Call Score{" "}
                {`(${this.state.data.call[0]} - ${this.state.data.call[1]}) %  `}
              </p>
              <hr className="filter-Hr"></hr>
              <div className="w-100 range-slider">
                <Box>
                  <Slider
                    value={this.state.data.call}
                    onChange={this.callLog}
                    className="call-pith"
                    valueLabelDisplay="auto"
                    marks={this.marks}
                    // disableSwap
                    name="call"
                  />
                </Box>
              </div>
            </div>
            {this.clintType === "SUPPORT" || !JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.team?.columns.includes("Pitch Score") ? (
              ""
            ) : (
              <div className="mb-3">
                <p className="mb-0 call-fillter-heading">
                  Pitch Score{" "}
                  {`(${this.state.data.pitch[0]} - ${this.state.data.pitch[1]}) %  `}
                </p>
                <hr className="filter-Hr"></hr>
                <div className="w-100 range-slider">
                  <Box>
                    <Slider
                      value={this.state.data.pitch}
                      onChange={this.pitchLog}
                      valueLabelDisplay="auto"
                      className="call-pith"
                      marks={this.marks}
                      // disableSwap
                      name="pitch"
                    />
                  </Box>
                </div>
              </div>
            )}
          </>
        );
      }

      case "REPORTS": {
        return (
          <ReportFilter reportFilter={this.reportFilter} resetFormState={this.resetFormState} reset={this.state.resetField} defaultReportData={this.defaultReportData} />
        )
      }
    }
  }

  render() {
    return (
      <div className="">
        <div>
          <SidebarComponent
            enableGestures={false}
            id="default1"
            ref={(Sidebar) => (this.sidebarObj = Sidebar)}
            created={this.onCreate}
            width="280px"
            type={this.type}
            position="Right"
            style={{ visibility: "hidden" }}
          ></SidebarComponent>
          <div className="switcher-wrapper call-filters side-fillters-scroler">
            <div className="switcher-btn" onClick={this.toggleClick}>
              {" "}
              <i className="bx bx-filter-alt"></i>
            </div>
            <div className="switcher-body">
              <Form ref={this.formRef}>
                <div className="d-flex top-heading align-items-center">
                  <div>
                    <h5 className="mb-0 text-uppercase font-14">
                      {this.props.componentType === "FILTER TEAM_TAB"
                        ? "FILTER TEAM"
                        : this.props.componentType}{" "}
                      {""}
                    </h5>
                  </div>
                  <button
                    type="button"
                    onClick={this.toggleClick}
                    className="btn-close ms-auto close-switcher"
                    aria-label="Close"
                  ></button>
                </div>

                <hr className="my-2" />

                <div className="filter_scroll px-1">{this.displayFilter()}</div>
                <div className="filters_btn bg-white py-2">
                  <button
                    type="button"
                    className="btn px-4 btn-light me-2 lh-base cancel-btn"
                    onClick={this.onResetClick}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn px-4 lh-base update-btn"
                    onClick={this.handleSubmit}
                    disabled={this.state.disableApply}
                  >
                    {" "}
                    {this.state.loader ? (
                      <Loading variant="light" />
                    ) : (
                      <>Apply</>
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    team: state.manage_teams.team,
    users: state.manage_users.users,
    Team: state.manage_team.Team,
    COE: state.manage_coe.coe
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterTeamList: (data) => {
      dispatch(filterTeamList(data));
    },
    resetFilter: () => {
      dispatch(resetFilter());
    },
    filterUserList: (data) => {
      dispatch(filterUserList(data));
    },
    resetUserFilter: () => {
      dispatch(resetUserFilter());
    },
    filterTeam: (data,id,col) => {
      if (data.team_fromD || data.team_toD || data.teamCOE || id) {
        console.log(data,id,"bskckcs")
        let date = {"userId": id, "coeIds": data.coeId ? data.coeId : localStorage.getItem("AGENT")?.selectedCoeRange, "fromD": data.team_fromD, "toD": data.team_toD }
        col  ? dispatch(getTeamCaller({"coeIds": data.coeId ? data.coeId : localStorage.getItem("AGENT")?.selectedCoeRange, "fromD": data.team_fromD, "toD": data.team_toD })).then(() => dispatch(filterTeam(data))) : dispatch(getTeam(date)).then(() => dispatch(filterTeam(data)))
        // localStorage.setItem("AGENT",JSON.stringify({"fromD":data.team_fromD,"toD":data.team_toD}))
      } else
        dispatch(filterTeam(data));
    },
    setCoe: (data) => {
      dispatch(setCOE(data))
    },

    resetTeamFilter: (idCOE) => {
      let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      let data = {"userId": JSON.parse(localStorage.getItem("USER_DETAIL")).userId , "coeIds": idCOE, "fromD": `${firstDay.getDate()}-${firstDay.getMonth() + 1}-${firstDay.getFullYear()}`, "toD": `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}` }
      dispatch(getTeam(data)).then(() => dispatch(resetTeamFilter()))

    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
