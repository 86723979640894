import { Card, Modal } from "react-bootstrap";
import * as React from 'react'
import { useHistory} from 'react-router-dom'

export default function MoreInfo(props){
    const history = useHistory()

    const redirectToUser = (id) => {
        history.push(`/team_member_detail?id=${id}`)
    }

    const conversion = (duration)=>{
        let hh = duration?.split(":")[0]
        let mm=duration?.split(":")[1]
        let ss = duration?.split(":")[2]
        console.log(ss)
        let text = ""
        if(mm>0){
          text = `${mm} Min. ${ss} Sec.`
        }
        else if(ss>0){
          text  = text + `${ss} Sec.`
        }
        // let text  = minutes + sec
        console.log(text)
        return text
    }
    console.log("MOREINFO",props)
    return(
        
        <Modal show={props.show} className="modal-dialog1" size="lg" >
            <Modal.Header>
                <button className="btn-close" onClick={props.closeButton}>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="modalcard mb-2">
                    <div className="modalcard-body">
                        <div className="row w-100 m-0">
                            <div className="col-6 ps-0">
                                <ul className="list-group list-group-flush" >
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-user me-1"/>
                                            {`${props?.customerName} (customer)`}
                                        </h6>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-time me-1"/>
                                        Date/Time:
                                        </h6>
                                        <span>{props?.date}</span>
                                    </li>
                                    {props?.talkDuration !== null ? <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-stopwatch me-1"/>
                                        Duration
                                        </h6>
                                        <span>{conversion(props?.talkDuration)}</span>
                                    </li> : ''}
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-time me-1"/>
                                        Processing Date/Time:
                                        </h6>
                                        <span>{props?.date}
                                            {/* | 14:20 Hrs */}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0" title="Click to redirect to Agent Details" onClick={() => redirectToUser(props?.userId)} style={{cursor:'pointer'}}>
                                            <i className="bx bx-user-voice me-1"/>
                                        {`${props?.sellerName} (seller)`}
                                        </h6>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-hash me-1"/>
                                        Seller Id:
                                        </h6>
                                        <span >{props?.sellerId}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-buildings me-1"/>
                                        COE:
                                        </h6>
                                        <span>{props?.coeName}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="ps-0">
                                <ul className="ps-0">
                                {props?.url !== null ? props?.typeOfCall=='SINGLE_CALL'?
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap moreInfoClass" >
                                    <h6 className="mb-0">
                                        <i className="bx bx-file me-1"/>
                                    File Name
                                    </h6>
                                    <span >{props?.url}
                                        <a  href={props?.url}  className="text-light">
                                            <i className="bx bx-download font-16 align-middle ms-1"/>
                                        </a>
                                    </span>
                                </li>:"" 
                                : '' }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}