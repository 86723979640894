import React, { useEffect, useState } from 'react'
import Loading from '../../Commons/Loading';
import { USER_DETAIL } from '../../../Constants/constant';
import AddNewCompetitor from './AddNewCompetitor';
import EditCompetitor from './EditCompetitor';
import ManageCompetitorsColumns from './ManageCompetitorsColumns';
import "../ManageUser/manageUser.css"

function ManageCompetitors(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [editCompetitorVisibility, setEditCompetitorVisibility] = useState(false);
    const [competitorData, setCompetitorData] = useState([]);

    useEffect(() => {
        document.title = "Competitors  - Odio"
    }, [])

    console.log(props, "list========>")

    return (
        <>
            {props.loader ? (
                <div className="loader-container">
                    <Loading variant="dark" />
                </div>
            ) : isOpen ? (
                <AddNewCompetitor setIsOpen={setIsOpen} getCompetitorsList={props?.getCompetitorsList} />
            ) : editCompetitorVisibility ? (
                <EditCompetitor
                    setEditCompetitorVisibility={setEditCompetitorVisibility}
                    selectedRowData={competitorData}
                    getCompetitorsList={props?.getCompetitorsList}
                />
            ) : (
                <div className="manage-moment-main-table mt-3">
                    <div className="d-flex top-fixed-box">
                        <div className=" mb-2 text-sm-left myTeamLHeader">
                            <h5 className="account-name account-id">Manage Competitors</h5>
                        </div>
                        <div className="nav-item add-coe-btn-top" style={{bottom:'-10px'}} role="presentation">
                            <a
                                type="button"
                                className="nav-link btn account-edit-btn edit-account w-100 text-nowrap"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                Add Competitors
                            </a>
                        </div>
                    </div>

                    <div className="view-team-card-details">
                        <ManageCompetitorsColumns
                            setEditCompetitorVisibility={setEditCompetitorVisibility}
                            setCompetitorData={setCompetitorData}
                            competitorsList={props?.competitorsList}
                            getCompetitorsList={props?.getCompetitorsList}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default ManageCompetitors