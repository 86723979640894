import React, { useState } from 'react'
import "../ManageUser/manageUser.css"
import { Button } from 'react-bootstrap';
import clientService from '../../Services/client.service';
import toaster from '../../Toast/toaster';

function EditCompetitor(props) {
    const [loading, setLoading] = useState(false)
    const [inputs, setInputs] = useState({
        competitorData: props?.selectedRowData ? [props?.selectedRowData] : []
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs(prevState => ({
            competitorData: [{
                ...prevState.competitorData[0],
                [name]: value
            }]
        }));
    };

    const handleAliasChange = (e, index) => {
        const { value } = e.target;
        setInputs(prevState => ({
            competitorData: [{
                ...prevState.competitorData[0],
                competitorAliases: prevState.competitorData[0].competitorAliases.map((alias, i) =>
                    i === index ? { aliasName: value } : alias
                )
            }]
        }));
    };

    const addAlias = () => {
        setInputs(prevState => ({
            competitorData: [{
                ...prevState.competitorData[0],
                competitorAliases: [...prevState.competitorData[0].competitorAliases, { aliasName: '' }]
            }]
        }));
    };

    const removeAlias = (index) => {
        setInputs(prevState => ({
            competitorData: [{
                ...prevState.competitorData[0],
                competitorAliases: prevState.competitorData[0].competitorAliases.filter((_, i) => i !== index)
            }]
        }));
    };

    const handleFormSubmit = (event) => {
        event.preventDefault(); 
        setLoading(true);
        console.log("Submitting to API with inputs:", inputs);
        clientService.updateCompetitor(inputs)
            .then((res) => {
                console.log("API Response:", res);
                toaster.success(res.data.message);
                props.setEditCompetitorVisibility(false)
                props.getCompetitorsList()
            })
            .catch((error) => {
                toaster.error(error)
                console.error("API Error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <div className="mt-3 addEmploy-form">
                <div className="d-flex align-items-center">
                    <div>
                        <i className="bx bxs-user me-1 font-22 edit-profile-icon" />
                    </div>
                    <h5 className="mb-0 edit-profile-header">Update Competitor</h5>
                </div>
                <hr />
                <form className="row g-3" onSubmit={handleFormSubmit}>
                    <div className='col-md-6' style={{ marginRight: '50px' }}>
                        <div className="col-md-12 mb-3">
                            <label className="form-label">
                                Competitor Name*
                            </label>
                            <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i className="bx bxs-user" />
                                </span>
                                <input
                                    placeholder="Enter Competitor Name"
                                    className="form-control"
                                    name="competitorName"
                                    value={inputs.competitorData[0].competitorName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="form-label">
                                Competitor Sector*
                            </label>
                            <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                    <i className="bx bxs-user" />
                                </span>
                                <input
                                    placeholder="Enter the sector"
                                    className="form-control"
                                    name="sector"
                                    value={inputs.competitorData[0].sector}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        {inputs.competitorData[0].competitorAliases.map((alias, index) => (
                            <div key={index} className="col-md-12 mb-3">
                                <label className="form-label">
                                    Alias Name
                                </label>
                                <div className='d-flex' style={{ gap: '15px' }}>
                                    <div className="input-group" style={{ flex: '6' }}>
                                        <span className="input-group-text bg-transparent">
                                            <i className="bx bxs-user" />
                                        </span>
                                        <input
                                            required
                                            placeholder={`Enter Alias Name`}
                                            className="form-control"
                                            name="aliasName"
                                            value={alias.aliasName}
                                            onChange={(e) => handleAliasChange(e, index)}
                                        />
                                    </div>
                                    <div className='' style={{ flex: '1' }}>
                                        <Button type="button" className='update-btn px-2 lh-base border-0' onClick={() => removeAlias(index)}>
                                            Remove
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Button type="button" className={`update-btn px-2 lh-base border-0 ${inputs.competitorData[0].competitorAliases.length === 0 ? "add-alias-btn" : ""}`} onClick={addAlias}>
                            Add Alias
                        </Button>
                    </div>
                    <div className="col-12 text-sm-right cancelUpadte-btn text-end">
                        <Button
                            className="me-2 px-4 lh-base"
                            variant="light"
                            onClick={() => props.setEditCompetitorVisibility(false)}
                        >
                            <span>
                                <i className="bx bx-arrow-back me-1 blk_clr"></i>
                            </span>
                            Cancel
                        </Button>
                        <Button type="submit" className="update-btn px-5 lh-base border-0" disabled={loading}>
                            Update
                        </Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditCompetitor