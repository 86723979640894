import React, { Fragment, useEffect, useState } from "react";
import { Route , BrowserRouter as Router, Switch} from "react-router-dom";
import Routing from "./Components/Commons/Routing";
import { AUTHENTICATED, conversionFormat } from "./Constants/constant";
import ToastContainer from './Components/Toast/components/ToastContainer';
import './Components/Toast/dist/ReactToastify.css';
import ForgotPassword from "./Components/Login/ForgotPassword";
import { connect } from "react-redux";
import {setUserDetails, setUserPermissions} from "./redux-container/login/action";
import {USER_DETAIL, USER_PERMISSIONS} from "./Constants/constant"
import GLIDUpload from "./Separate-pages/GLIDUpload";
import RecordingSubmit from "./Separate-pages/RecordingSubmit";
import Question from "./Components/Questions/question";
import { DataProvider } from "./Components/Dashboard/DashboardSales/SalesDashboard/WordCloudApiState";
import BalicOnboard from "./Separate-pages/BalicOnboard";
import BalicOnBoardDetails from "./Separate-pages/BalicOnboardDetails";
import ForbiddenAccess from "./Separate-pages/ForbiddenAccess";
import { apiCall } from "./Components/Services/Interceptor";

function App(props) { 

  
  useEffect(() => {
    const storedFormat = JSON.parse(localStorage.getItem("TIME_FORMAT"));
    const fetchData = async () => {
        try {
          var timeFormat = await apiCall.get('odio/api/client/get-calendar-config').then((res)=>{ return res.data.data.filter((val)=>val.key == "DATE_FORMAT")[0].value})
          localStorage.setItem("TIME_FORMAT", JSON.stringify(timeFormat && conversionFormat[timeFormat]));
        } catch (error) {
          console.error(error);
          localStorage.setItem("TIME_FORMAT", JSON.stringify({ format: 'dd-MM-yyyy', value: "DD-MM-YYYY" }));
        }
    };

    if (!storedFormat || !storedFormat.format || typeof(storedFormat) == "string") {
    fetchData();
    }
    
    let auth = localStorage.getItem(AUTHENTICATED);
    if(auth)
      {
        let details = localStorage.getItem(USER_DETAIL);
        let permissions = localStorage.getItem(USER_PERMISSIONS);
        props.setUserDetails(details);
        props.setUserPermission(permissions);
      }
       if(!navigator.onLine){
        setTimeout(() => {
          window.location.reload(false)
        }, 30000);  
       }

  },[])
  
  return ( 
    <Fragment>
      <Router>
        <DataProvider>
          <Switch>
            <Route exact path="/forgot_password">
              <ForgotPassword/> 
            </Route>
            <Route exact path="/question">
              <Question/>
            </Route>
            <Route exact path="/balic_onboard">
              <BalicOnboard/>
            </Route>
            <Route exact path="/onboarding_details">
              <BalicOnBoardDetails/>
            </Route>
            <Route exact path="/403">
              <ForbiddenAccess/>
            </Route>
            <Route path="/">         
              <Routing/>
            </Route>
          </Switch>  
          </DataProvider>
      </Router>
     <ToastContainer />
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) =>{
  return{
      setUserDetails: (details) =>{dispatch(setUserDetails(details))},
      setUserPermission: (permissions)=>{dispatch(setUserPermissions(permissions))},
  }
}

export default connect(null, mapDispatchToProps) (App);
